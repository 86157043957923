<template>
  <div class="culture">
    <div style="height: 80px"></div>
    <banner title="山东钾能 钾通天下"  subTitle='用优质肥料，做精品农业'/>
    <div class="body-box">
      <div class="c-width cf w1200">
        <div class="l-menu fl">
          <p class="menu-name">企业文化</p>
          <p
            v-for="(item, index) in data"
            :key="item.id"
            class="link"
            :class="[item.id == fid ? 'on' : '']"
            @click="selLink({ index }, item.id)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="r-box fr">
          <div class="r-header cf">
            <h4 class="fl">{{ data[index].title }}</h4>
            <div class="fr path">首页 > 企业文化 > {{ data[index].title }}</div>
          </div>
          <div class="r-context">
            <div v-if="fid != 4" class="cf">
              <img class="fl" :src="data[index].imgSrc" alt="" />
              <div class="r-text fr" v-html="data[index].context"></div>
            </div>
            <div v-if="fid == 4">
              <video class="video" controls :src="data[index].videoSrc"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../data/data";
import banner from "@/components/banner";
export default {
  components: {
    banner,
  },
  data() {
    return {
      data: data,
      fid: 1,
      index: 0,
    };
  },
  methods: {
    selLink: function (index, fid) {
      this.index = index.index;
      this.fid = fid;
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>