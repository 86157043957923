export default [
    {
        id:1,
        title: '公司介绍',
        imgSrc: require('../../../assets/images/gsjs_img.png'),
        context: `<p>山东钾能化工有限公司成立于2015年8月，总部位于中国北方物流之都的山东临沂。成立之初取得青海盐湖集团授权,是青海盐湖集团指定的专业经营代理商，依托盐湖集团“三大基地、五大产业群”的战略布局，主营硝酸钾、氢氧化钾、碳酸钾、氯化钾、尿素、PVC、PP等优势产品及各类化工产品和肥料，公司自成立以来，发展迅速，贸易网络遍及国内24省，出口产品远销东南亚、日韩、欧美和非洲等地。硝酸钾年销售量达6万吨，氢氧化钾年销售量达4万吨。</p>
        <p>公司创立以来，始终秉持品质为上、价格合理、服务专业的理念，以诚信的态度和高效的作风赢得海内外客户的高度评价认可。</p>
    ` 
    },
//     {
//         id:2,
//         title: '领导致辞',
//         imgSrc: require('../../../assets/images/0114.jpg'),
//         context: `
//         <p>领导指出，盐湖是青海最重要的资源。要制定正确的资源战略，加强顶层设计，搞好开发利用。循环利用是转变经济发展模式的要求，全国都应该走这样的路。青海要把这件事情办好，发挥示范作用。青海资源也是全国资源，要有全国一盘棋思想，在保护生态环境的前提下搞好开发利用。</p>`
//     },{
//         id:3,
//         title: '品牌介绍',
//         imgSrc: require('../../../assets/images/0212121.jpg'),
//         context: `<p>上海盐湖文通化工有限公司是一家由青海盐湖工业股份有限公司和文通钾盐集团有限公司为主体，子公司
//         青海盐湖元通钾肥股份有限公司和青海文通盐桥化肥有限公司共同出资组建的销售公司。上海盐湖文通是
//         随着青海盐湖工业股份有限公司综合利用化工项目的不断建成以及在“三大基地、五大产业群”的战略布
//         局大背景下，是迎合青海盐湖工业股份有限公司在深化营销体制改革，创新营销模式时创立的新型公司，
//         旨在通过借势、借人、借力、借船出海，达到互利共赢的目的。</p>
//  `

//     },
    {
        id:4,
        title: '企业视频',
        videoSrc: 'http://www.sdjnhg.com.cn/mp4/jn.mp4',

    }
];
